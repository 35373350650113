@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.toolkits-about {
    overflow: hidden;

    & + section.blog-posts {
        & > .m-container {
            padding-top: 40px;
            
            @media (min-width: $medium-size) {
                padding-top: 100px;
            }
        }
    }
    & + link {
        & + section.blog-posts {
            & > .m-container {
                padding-top: 40px;

                @media (min-width: $medium-size) {
                    padding-top: 100px;
                }
            }
        }
    }
}
.toolkits-about__container {
    position: relative;

    @media (min-width: $small-size) {
        display: flex;
        flex-direction: column;
    }
    @media (min-width: $medium-size) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        align-items: center;

        padding-bottom: 100px;
    }
    @media (min-width: $large-size) {
        grid-column-gap: 24px;
    }
}

.toolkits-about__heading {
    margin-bottom: 20px;

    @media (min-width: $medium-size) {
        white-space: nowrap;
    }
}

.toolkits-about__wr-text {
    p {
        &:first-child {
            margin-bottom: 24px;
        }
    }
}

.toolkits-about__col-text {
    margin-bottom: 30px;

    @media (min-width: $small-size) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    @media (min-width: $medium-size) {
        margin-bottom: 0;
    }
    @media (min-width: $large-size) {
        padding-top: 20px;
    }
}

.tookits-about__wr-btns {
    display: flex;
    flex-direction: column;

    margin-top: 40px;

    @media (min-width: $small-mid-size) {
        flex-direction: row;
    }
}
.toolkits-about__btn {
    text-align: center;

    &:not(:last-child) {
        margin-bottom: 24px;

        @media (min-width: $small-mid-size) {
            margin-bottom: 0;
            margin-right: 24px;
        }
        @media (min-width: $small-size) {
            margin-right: 48px;
        }
    }

}
.toolkits-about__col-wheel {
    width: 100%;
    max-width: 530px;

    @include img-wrap;
    @include aspect-ratio-w-pseudo(1);

    svg,
    img {
        object-fit: contain;
    }    

    @media (min-width: $smallest-size) {
        margin-left: auto;
        margin-right: auto;
    }
    @media (min-width: $largest-size) {
        margin-top: 60px;
        margin-right: 0;
    }
}
.tookits-about__wr-btns {
    & > .m-btn_color-laguna {
        @include btn__color-orange-dark;
    }
    & > .m-btn_style-hollow-laguna {
        @include btn__style-hollow-orange;
    }
}